.container-input {
  width: 450px;
  height: 180px;
  margin: 10px;
}

.input-size {
  width: 365px;
  height: 60px;
}

.radio-group {
  margin: 20px 0;
  gap: 40px;
}
.form-check, label {
  cursor: pointer;
}

.text-input {
  font-size: 19px;
}

.label-large {
  font-size: 19px;
}

.container-feedback {
  margin-top: 60px;
  max-width: 500px; 
}

.header-large {
  font-size: 34px;
  font-weight: bold;
}

.progress-div {
  height: 74vh;
  background-color: white;
}

.logo-chat {
  margin: 30px 0;
  position: absolute;
  width: 10rem;
}

.buttons-size {
  width: 9rem;
  height: 3rem;
}

.buttons-back {
  margin-top: 50px;
}

.card-div{
  height: 100%;
}

@media screen and (max-width: 767px) {
  .container-input{width: auto; height: auto;}
  .input-size{width: auto;}
  .buttons-back{width: auto; height: auto; flex-direction: column;}
  .bg-widget{margin-top: auto; flex-direction: column;}
  .logo-chat {margin: 30px 0; position: unset; width: 10rem;}
  .progress-div {height: auto;}
  .card-div{height: auto;}
  }