/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
.widgetBlock {
  padding: 12px 0;
  background-color: #F7F8FB;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.smallWidget .mutedPink {
  background-color: rgba(255, 86, 104, 0.1882352941);
}
.smallWidget .mutedYellow {
  background-color: rgba(255, 196, 5, 0.1882352941);
}
.smallWidget .mutedTeal {
  background-color: rgba(65, 213, 226, 0.1882352941);
}
.smallWidget .mutedViolet {
  background-color: rgba(77, 83, 224, 0.1882352941);
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;
}
@media (max-width: 767.98px) {
  .statsBtn {
    padding: 20px 24px;
  }
}
.statsBtn .pieImg {
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

@media (max-width: 1199.98px) {
  .responsiveWidget {
    padding: 24px 12px;
  }
}/*# sourceMappingURL=Dashboard.module.css.map */