.auth-page {
  height: 100vh;
  background-color: $white;

  @media (max-width: breakpoint-max(md)) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 350px) {
    height: auto;
    padding: 50px 0;
  }

  .left-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    margin: 5rem 0;

    .form-control {
      color: $base-dark-gray;
    }

    form {
      width: 90%;

      @media (max-width: breakpoint-max(sm)) {
        width: 80%;
      }
    }
  }

  .right-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $white;

    img.loginImage {
      width: auto;
      height: 85vh;
      position: absolute;
      top: 0;
      right: 0;


      @media (max-width: 1400px) {
        height: 80vh;
      }

      @media (max-width: 1300px) {
        height: 73vh;
      }

      @media (max-width: 1160px) {
        height: 70vh;
      }

      @media (max-width: 1100px) {
        height: 65vh;
      }

      @media (max-width: breakpoint-max(md)) {
        display: none;
      }

    }

    img.registerImage {
      width: auto;
      height: 70vh;

      @media (max-width: 1200px) {
        width: 50vw;
        height: auto;
      }

    }

    @media (max-width: breakpoint-max(md)) {
      display: none;
    }
  }
}

.auth-header {
  font-size: $headline-large;
  font-weight: $font-weight-semi-bold;
  color: $dark-green;
  text-align: center;

  @media (max-width: breakpoint-max(md)) {
    margin-top: 2rem;
  }
}

.logo-row {
  @media (max-width: breakpoint-max(md)) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .logo-block {
    position: absolute;
    top: 20px;
    left: 50px;
    width: 9rem;
    z-index: 1;

    @media screen and (max-width: 1200px) {
      left: 30px;
    }

    @media screen and (max-width: 1200px) {
      left: 30px;
    }

    @media (max-width: breakpoint-max(md)) {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}

a {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.auth-info {
  padding: 18px 21px;
  border-radius: .5rem;
  background: $body-bg;
  color: $base-dark-gray;
}

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.dividing-line:before,
.dividing-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $base-middle-gray;
  margin: auto;
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.socials {
  a {
    margin: 0 .5rem;
  }
}

.formText {
  font-size: $font-size-base;
}

.input {
  background-color: $light-gray;
  font-size: $font-size-sm;
  padding: 25px 20px;
}

.select {
  height: auto;
  padding: 12px 15px;
  background-color: $light-gray;
}


.auth-page.register {
  height: auto;

  @media (max-height: 740px) {
    .registerDiv {
      position: relative;

      @media (min-width: 992px) {
        margin: 60px 0;
      }
    }
  }

  @media (max-width: 1500px) {
    height: auto;
  }

  @media (max-width: breakpoint-max(md)) {
    height: auto;
    padding: 50px 0;
  }

}