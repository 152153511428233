@import "../../styles/helpers";

.widgetBlock {
  padding: 12px 0;
  background-color: $base-light-gray;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .mutedPink {
    background-color: #FF566830;
  }

  .mutedYellow {
    background-color: #FFC40530;
  }

  .mutedTeal {
    background-color: #41D5E230;
  }

  .mutedViolet {
    background-color: #4D53E030;
  }

  //  доделать для остальных цветов прогресс баров
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 24px;
  }

  .pieImg {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.responsiveWidget {
  @media (max-width: breakpoint-max(lg)) {
    padding: 24px 12px;
  }

}

.container {
  padding: 0 13px;
  justify-content: space-between;
}

.bodyPadding {
  padding: 0 0px !important;
}

.ColBody {
  padding: 0 0px !important;
}

.col {

  &.customGrow {
    @media (max-width: 1002px) {
      flex-grow: 1;
      width: auto;
    }

    @media (max-width: 767px) {
      flex-grow: 0;
      width: 100vw;
      display: block;
    }
  }

  width: 310px;

  @media (max-width: 1810px) {
    width: 270px;
  }

  @media (max-width: 1776px) {
    width: 266px;
  }

  @media (max-width: 1674px) {
    width: 250px;
  }

  @media (max-width: 1580px) {
    width: 233px;
  }

  @media (max-width: 1492px) {
    width: 220px;
  }

  @media (max-width: 1479px) {
    width: 218px;
  }

  @media (max-width: 1461px) {
    width: 200px;
  }

  @media (max-width: 1425px) {
    width: 190px;
  }

  @media (max-width: 1322px) {
    width: 175px;
  }

  @media (max-width: 1240px) {
    width: 160px;
  }

  @media (max-width: 1227px) {
    width: 155px;
  }

  @media (max-width: 1112px) {
    width: 145px;
  }

  @media (max-width: 1054px) {
    width: 135px;
  }

  @media (max-width: 1033px) {
    width: 125px;
  }

  @media (max-width: 1002px) {
    width: 125px;
  }

  @media (max-width: 952px) {
    width: 120px;
    display: flex;
    align-items: stretch;
  }

  @media (max-width: 927px) {
    width: 115px;
  }

  @media (max-width: 902px) {
    width: 110px;
  }

  @media (max-width: 877px) {
    width: 100px;
  }

  @media (max-width: 827px) {
    width: 90px;
  } 

  @media (max-width: 827px) {
    width: 90px;
  }

  @media (max-width: 777px) {
    width: 80px;
  }

  h1 {
    @media (max-width: 1580px) {
      font-size: 1.8rem;
    }

    @media (max-width: 1225px) {
      font-size: 1.6rem;
    }

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  h4 {
    @media (max-width: 1425px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1225px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1147px) {
      font-size: 1rem;
    }

    @media (max-width: 953px) {
      word-break: break-word;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  p {
    @media (max-width: 1325px) {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 767px) {
    width: 100vw;
    display: block;
  }
}