@import "../../styles/helpers";

.widgetBlock {
  padding: 12px 0;
  background-color: $base-light-gray;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .mutedPink {
    background-color: #FF566830;
  }

  .mutedYellow {
    background-color: #FFC40530;
  }

  .mutedTeal {
    background-color: #41D5E230;
  }

  .mutedViolet {
    background-color: #4D53E030;
  }

  //  доделать для остальных цветов прогресс баров
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 24px;
  }

  .pieImg {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.responsiveWidget {
  @media (max-width: breakpoint-max(lg)) {
    padding: 24px 12px;
  }
}

.cardContainer {
  gap: 15px
}

.divCard {
  width: 370px;

  @media (max-width: 1839px) {
    width: 360px;
  }

  @media (max-width: 1799px) {
    width: 350px;
  }

  @media (max-width: 1760px) {
    width: 340px;
  }

  @media (max-width: 1719px) {
    width: 330px;
  }

  @media (max-width: 1679px) {
    width: 320px;
  }

  @media (max-width: 1639px) {
    width: 310px;
  }

  @media (max-width: 1599px) {
    width: 300px;
  }

  @media (max-width: 1559px) {
    width: 290px;
  }

  @media (max-width: 1519px) {
    width: 280px;
  }

  @media (max-width: 1479px) {
    width: 370px;
  }

  @media (max-width: 1454px) {
    width: 360px;
  }

  @media (max-width: 1424px) {
    width: 350px;
  }

  @media (max-width: 1394px) {
    width: 340px;
  }

  @media (max-width: 1364px) {
    width: 325px;
  }

  @media (max-width: 1319px) {
    width: 310px;
  }

  @media (max-width: 1274px) {
    width: 300px;
  }

  @media (max-width: 1244px) {
    width: 290px;
  }

  @media (max-width: 1214px) {
    width: 280px;
  }

  @media (max-width: 1184px) {
    width: 270px;
  }

  @media (max-width: 1154px) {
    width: 30%;
  }

  @media (max-width: 991px) {
    width: 29vw;
  }

  @media (max-width: 826px) {
    width: 27vw;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.customGrow {
  width: 350px;

  @media (max-width: 1874px) {
    width: 345px;
  }

  @media (max-width: 1849px) {
    width: 340px;
  }

  @media (max-width: 1804px) {
    width: 330px;
  }

  @media (max-width: 1754px) {
    width: 320px;
  }

  @media (max-width: 1704px) {
    width: 310px;
  }

  @media (max-width: 1654px) {
    width: 300px;
  }

  @media (max-width: 1604px) {
    width: 290px;
  }

  @media (max-width: 1554px) {
    width: 280px;
  }

  @media (max-width: 1504px) {
    width: 270px;
  }

  @media (max-width: 1454px) {
    width: 260px;
  }

  @media (max-width: 1404px) {
    width: 440px;
  }

  @media (max-width: 1394px) {
    width: 320px;
  }

  @media (max-width: 1369px) {
    width: 310px;
  }

  @media (max-width: 1329px) {
    width: 290px;
  }

  @media (max-width: 1249px) {
    width: 280px;
  }

  @media (max-width: 1209px) {
    width: 270px;
  }

  @media (max-width: 1169px) {
    width: 260px;
  }

  @media (max-width: 1199px) {
    width: 250px;
  }

  @media (max-width: 1089px) {
    width: 330px;
  }

  @media (max-width: 1064px) {
    width: 320px;
  }

  @media (max-width: 1034px) {
    width: 310px;
  }

  @media (max-width: 1004px) {
    width: 300px;
  }

  @media (max-width: 974px) {
    width: 45vw;
  }


  @media (max-width: 767px) {
    width: 100%;
  }

}