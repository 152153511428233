/**
*Corrige erro de padding-right quando o Swal.fire de carregamento é utilizado.
*/
body {
    padding-right: 0 !important;
}

.navTab {
    height: 48px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.badgeChat{
    margin-left: 1rem;
}

@media screen and (max-width: 1623px) {
    .badgeChat {margin-left: 0.5rem;}
    }

@media screen and (max-width: 1600px) {
    .navTab {
        width: auto;
        height: 50px;
        align-items: center;
    }
}

@media screen and (max-width: 1400px) {
    .navTab {
        width: auto;
        height: 66px;
        align-items: center;
    }
}

@media screen and (max-width: 447px) {
    .navTab {margin-left: 0;}
    }

@media screen and (max-width: 340px) {
    .navTab {
        width: auto;
        justify-content: auto;
        align-items: center;
    }
}

@media screen and (max-width: 310px) {
    .navTab {
        width: auto;
        height: auto;
        flex-direction: column;
        padding: 0 9px 10px 0;
    }
}