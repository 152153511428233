.fc-daygrid-event-harness {
    background: #4D53E0;
    color: #fff;
}

.fc-event-title,
.fc-event-time {
    color: #fff;
}

.check {
    position: absolute;
    left: 420px;
    top: 40px;
}

#message {
    min-height: 150px;
}

.container-fluid {
    padding: 20px;
}

.btn-save {
    border: 2px solid #4D53E0;
    padding: 5px 20px;
}

.btn-cancel {
    border: 2px solid #494949;
    padding: 5px 20px;
}

.btn-delete {
    position: absolute;
    border: 2px solid #ff0303;
    padding: 5px 20px;
    left: 25px;
}

@media screen and (max-width: 991px) {
    .check {
        position: relative;
        left: 40px;
        top: 0;
    }

    .container-fluid {
        overflow: hidden;
    }

    .fc-daygrid-day-frame {
        height: 50px;
    }

    .fc-header-toolbar {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 510px;
    }

    .fc-view-harness.fc-view-harness-active {
        width: 100%;
        overflow: auto;
    }

    .fc-timeGridWeek-view.fc-view.fc-timegrid {
        min-width: 30rem;
    }
}

@media screen and (max-width: 500px) {
    .btn-delete {
        position: inherit;
        left: 0;
    }
}